import './App.css';

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles';

import EndOfDayReport from './EndOfDayReport';

import logo from './miaxdx-logo.png';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
      letterSpacing: "0.05em",
      fontSize: "13px",
      color: "#37474F",
    },
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
      <Router>
        <Route render={({ location }) => (
          <div className="App">
            <header className="App-header">
              <img src={logo} alt="MIAXdx" />
            </header>
            <main className="App-main">
              <Switch key={location.key} location={location}>
                <Route path="/" component={EndOfDayReport} />
              </Switch>
            </main>
            <footer className="App-footer">&copy;{new Date().getFullYear()} LedgerX LLC d/b/a MIAX Derivatives Exchange</footer>
          </div>
        )}/>
      </Router>
      </ThemeProvider>
    );
  }
}

export default App;
